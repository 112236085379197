
import { defineComponent } from "vue";
import { Profile } from "../models/Profile";
import store from "../store/index";

export default defineComponent({
  name: "Profile",
  async beforeMount() {
    await store.dispatch("getProfile");
  },
  data() {
    return {
      messageShow: false,
      password: "",
    };
  },
  computed: {
    profile(): Profile {
      return store.state.profile;
    },
  },
  methods: {
    async updatePassword() {
      let res = await store.dispatch("updateProfilePassword", this.password);
      if (res){ 
        this.messageShow = true; 
      } else {
        alert("Error updating password!");
      }
    },
  },
});
